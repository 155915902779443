import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';

import { closePopup } from '../actions/popup';

const DEFAULT_COLOR = '#5ca3b6';

class ContactPopup extends Component {

  render() {
    const { shop_contact, onClosePopup, template_color = DEFAULT_COLOR, index } = this.props;

    return (
      <div className="shop" style={{ zIndex: BASE_ZINDEX + index, position: 'absolute' }}>
        <div id="popup-contact" style={{ position: 'fixed', top: 0 }}>
          <div className="contact">
            <div className="contact-container contact-only">
              <a className="close-order" onClick={onClosePopup}>×</a>
              <p>If you have any questions, please contact:</p>
              <h3>{shop_contact.contact_name}</h3>
              <p>
                {/* <i>{sales_rep.contact_position}</i>
                <br /> */}
                <a style={{ color: template_color }} href={`mailto:${shop_contact.contact_email}`}>{shop_contact.contact_email}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup())
});

const ConnectedContactPopup = connect(null, mapDispatchToProps)(ContactPopup);
export default ConnectedContactPopup;

